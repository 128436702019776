<script setup lang="ts">
import {ref} from "vue";
import type {Seas} from "~/types/directus.types";
import type {Crumb} from "~/types/app.types";

const { logout, client } = useDirectus()
const { t } = useI18n()
const router = useRouter()
const crumbs = useState<Crumb[]>('crumbs', () => [])

type SeasSubtype = Pick<Seas, 'name' | 'primary_color' | 'id'> | undefined

interface RequestQuery {
  seas: SeasSubtype[]
}

const sea = useState('sea')

await client.query<RequestQuery>(`
  query {
    seas (limit: 1) {
      primary_color
      name
      id
    }
  }
`).then((res) => {
  sea.value = res.seas[0]
})

async function logoutAction() {
  await logout()
  router.push('/login')
}

const menuLinks = [
  {
    label: t('Dashboard'),
    icon: 'i-ph-house',
    to: '/admin/dashboard',
  },
  {
    label: t('Members'),
    icon: 'i-ph-users',
    to: '/admin/members',
  },
  {
    label: t('Courses'),
    icon: 'i-ph-graduation-cap',
    to: '/admin/courses',
  },
  {
    label: t('Media'),
    icon: 'i-ph-screencast',
    to: '/admin/media',
  },
  {
    label: t('Settings'),
    icon: 'i-ph-sliders-horizontal',
    to: '/admin/settings',
  },
]
</script>

<template>
  <div class="drawer drawer-mobile lg:drawer-open">
    <input id="ui-global-drawer" type="checkbox" class="drawer-toggle">
    <div class="drawer-content">
      <!-- Page content here -->
      <UContainer class="p-6">
        <div class="flex mb-8 items-center">
          <div class="mr-4 lg:hidden">
            <label for="ui-global-drawer" class="btn btn-circle btn-sm btn-outline">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-5 h-5 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" /></svg>
            </label>
          </div>
          <div class="text-sm breadcrumbs flex-1  border-b ">
            <ul>
              <li>
                <NuxtLink :to="({ name: 'admin' })" :title="t(`crumb.admin`)">
                  <UIcon name="i-ph-house-duotone" />
                </NuxtLink>
              </li>
              <li v-for="crumb in crumbs" :key="crumb.link">
                <NuxtLink v-if="crumb.link" :to="crumb.link" :title="crumb.label">
                  <span>{{ crumb.label }}</span>
                </NuxtLink>
                <span v-else>{{ crumb.label }}</span>
              </li>
            </ul>
          </div>
        </div>
        <slot />
      </UContainer>
    </div>
    <div class="drawer-side border-r">
      <label for="ui-global-drawer" class="drawer-overlay" />
      <div class="flex flex-col h-full w-64">
        <ul class="menu p-4  bg-base-100 text-base-content h-full">
          <!-- Sidebar content here -->
          <li class="p-4 menu-title">
            <span class="normal-case text-primary text-xl">Oceana</span>
          </li>
          <li v-for="link in menuLinks" :key="link.to" :class="{ disabled: link.disabled }">
            <span v-if="link.disabled">
              <UIcon :name="link.icon" />
              {{ link.label }}
            </span>
            <NuxtLink v-else :to="link.to" active-class="active">
              <UIcon :name="link.icon" />
              {{ link.label }}
            </NuxtLink>
          </li>
        </ul>
        <div class="bg-base-100 flex w-full justify-between items-end p-4">
          <div class="dropdown dropdown-top">
            <label tabindex="0" class="cursor-pointer">
              <div class="avatar placeholder">
                <div class="w-14 mask mask-squircle bg-neutral text-neutral-content">
                  <UIcon name="i-ph-user-duotone" class="text-3xl" />
                </div>
              </div>
            </label>
            <ul tabindex="0" class="menu menu-compact dropdown-content mb-2 mt-3 p-2 shadow-lg bg-white rounded-box w-52 z-10">
              <li>
                <NuxtLink to="/admin/profile" class="justify-between">
                  {{ $t('Profile') }}
                </NuxtLink>
              </li>
              <li>
                <NuxtLink to="/admin/profile" class="justify-between">
                  {{ $t('Notifications') }}
                  <span class="badge badge-primary">{{ $t('2') }}</span>
                </NuxtLink>
              </li>
              <li><a @click="logoutAction">{{ $t('Logout') }}</a></li>
            </ul>
          </div>
          <div class="text-xs text-base-400">
            Made in {{ (new Date()).getFullYear() }} with <UIcon name="i-ph-heart-duotone" class="text-red-600 animate-pulse align-text-bottom" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
